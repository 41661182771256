import { createBrowserRouter } from 'react-router-dom';
import React from 'react';

import {
  LINK_TO_CHANNELS,
  LINK_TO_CHANNELS_STATISTICS,
  LINK_TO_CREATE_ORDER,
  LINK_TO_DICTIONARY,
  LINK_TO_MAIN,
  LINK_TO_MARGIN_REPORT,
  LINK_TO_ORDER_INFO,
  LINK_TO_ORDERS,
  LINK_TO_PAYMENT_CONFIRMATION,
  LINK_TO_PRICE_CALCULATOR,
  LINK_TO_PRICES,
  LINK_TO_REPORT_ACCOUNTING,
  LINK_TO_REPORT_ON_BUYER,
  LINK_TO_ROLE_MANAGEMENT,
  LINK_TO_STATISTIC,
  LINK_TO_TIME_OF_PLACEMENT,
} from './constants';

import { SideBarLayout } from 'components/layouts/main-layout';

const MainPage = React.lazy(() => import('../pages/main'));
const CreateOrderPage = React.lazy(() => import('../pages/CreateOrder'));
const OrdersPage = React.lazy(() => import('../pages/orders'));
const OrderInfoPage = React.lazy(() => import('../pages/order-info'));
const OrderStatistics = React.lazy(() => import('../pages/OrderStatistics'));
const ChannelsPage = React.lazy(() => import('../pages/channels'));
const MarginReportPage = React.lazy(() => import('../pages/PersonalArea/margin-report'));
const PaymentConfirmationsPage = React.lazy(() => import('../pages/PersonalArea/payment-confirmations'));
const ReportAccountingPage = React.lazy(() => import('../pages/PersonalArea/report-accounting'));
const DictionaryPage = React.lazy(() => import('../pages/dictionary'));
const PriceCalcPage = React.lazy(() => import('../pages/price-calc'));
const PricesPage = React.lazy(() => import('../pages/PersonalArea/prices'));
const RoleManagementPage = React.lazy(() => import('../pages/PersonalArea/role-management'));
const ChannelsStatistics = React.lazy(() => import('../pages/PersonalArea/ChannelsStatistics/ChannelsStatistics'));
const ReportOnBuyers = React.lazy(() => import('../pages/PersonalArea/ReportOnBuyers/ReportOnBuyers'));
const TimeOfPlacement = React.lazy(() => import('../pages/TimeOfPlacement'));
const PersonalReportOnBuyer = React.lazy(
  () => import('../pages/PersonalArea/PersonalReportOnBuyer/PersonalReportOnBuyer'),
);

export const router = createBrowserRouter([
  {
    element: <SideBarLayout />, // Общий layout для всех дочерних маршрутов
    children: [
      {
        path: LINK_TO_MAIN,
        element: <MainPage />,
      },
      {
        path: LINK_TO_CREATE_ORDER,
        element: <CreateOrderPage />,
      },
      {
        path: LINK_TO_ORDERS,
        element: <OrdersPage />,
      },
      {
        path: LINK_TO_ORDER_INFO,
        element: <OrderInfoPage />,
      },
      {
        path: LINK_TO_CHANNELS,
        element: <ChannelsPage />,
      },
      {
        path: LINK_TO_STATISTIC,
        element: <OrderStatistics />,
      },
      {
        path: LINK_TO_PRICE_CALCULATOR,
        element: <PriceCalcPage />,
      },
      {
        path: LINK_TO_PRICES,
        element: <PricesPage />,
      },
      {
        path: LINK_TO_ROLE_MANAGEMENT,
        element: <RoleManagementPage />,
      },
      {
        path: LINK_TO_MARGIN_REPORT,
        element: <MarginReportPage />,
      },
      {
        path: LINK_TO_REPORT_ACCOUNTING,
        element: <ReportAccountingPage />,
      },
      {
        path: LINK_TO_PAYMENT_CONFIRMATION,
        element: <PaymentConfirmationsPage />,
      },
      {
        path: LINK_TO_CHANNELS_STATISTICS,
        element: <ChannelsStatistics />,
      },
      {
        path: LINK_TO_REPORT_ON_BUYER,
        element: <ReportOnBuyers />,
      },
      {
        path: `${LINK_TO_REPORT_ON_BUYER}/:id`,
        element: <PersonalReportOnBuyer />,
      },
      {
        path: LINK_TO_DICTIONARY,
        element: <DictionaryPage />,
      },
      {
        path: LINK_TO_TIME_OF_PLACEMENT,
        element: <TimeOfPlacement />,
      },
    ],
  },
]);
