import { useGetLocale } from 'api/locale';

const features = {
  removeBanner: process.env.REACT_APP_FEATURE_REMOVE_BANNERS,
};

export const useLocale = () => {
  const { data: locale, isPending } = useGetLocale();

  const getStatusRemoveBanners = () => {
    return locale?.localeFeatures.find((feature) => feature.featureId === features.removeBanner)?.isEnabled || false;
  };

  return {
    locale: locale!,
    isPending,
    getStatusRemoveBanners,
  };
};
