import { RouterProvider } from 'react-router-dom';
import { Suspense } from 'react';
import { ConfigProvider } from 'antd';
import dayjs from 'dayjs';

import ruLocale from 'antd/locale/ru_RU';

import { router } from './features/router';
import { Loader } from './components/loader';

import { CheckAuthorization } from 'components/layouts/check-authorization';
import { LocaleContext } from 'features/context/localeContext';
import { useLocale } from 'features/hooks/useLocale';

dayjs.locale('ru', { weekStart: 1 });

export const App = () => {
  const { getStatusRemoveBanners, isPending, locale } = useLocale();

  return (
    <Suspense fallback={<Loader />}>
      <ConfigProvider locale={ruLocale}>
        <CheckAuthorization>
          <LocaleContext.Provider value={{ getStatusRemoveBanners, isPending, locale }}>
            <RouterProvider router={router} />
          </LocaleContext.Provider>
        </CheckAuthorization>
      </ConfigProvider>
    </Suspense>
  );
};
