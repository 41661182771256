// request.ts
import axios, { AxiosError } from 'axios';

import { authService } from './auth';

import { isDev } from 'features/constants';
import { store } from 'features/store/store';
import { authActions } from 'features/store/slices/auth';

const baseURL = isDev ? process.env.REACT_APP_SERVER_URL_DEV : process.env.REACT_APP_SERVER_URL_PROD;

let isLoggingOut = false; // Флаг, чтобы предотвратить множественные логауты

export const request = axios.create({
  baseURL: baseURL,
  withCredentials: true,
  headers: {
    'telegram-data': (window as any).Telegram?.WebApp?.initData || '',
  },
});

export const requestToNexus = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL_NEXUS,
});

request.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: AxiosError) => {
    const { response } = error;

    if (
      (response?.status === 401 || response?.status === 403) &&
      !isLoggingOut &&
      !response?.config?.url?.includes('/auth')
    ) {
      isLoggingOut = true; // Устанавливаем флаг, чтобы предотвратить повторный вызов логаута

      try {
        await authService.logout(); // Выполняем логаут
        store.dispatch(authActions.logoutUser()); // Обнуляем состояние пользователя в Redux
      } finally {
        isLoggingOut = false; // Сбрасываем флаг
      }
    }

    return Promise.reject(error);
  },
);
