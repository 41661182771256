import { Button, Drawer } from 'antd';
import { useState } from 'react';
import { CloseCircleOutlined, MenuOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

import { Menu } from './menu';
import { Logout } from './logout';

import LangSwitcher from '../lang-switcher';
import { CheckPermissionContent } from '../../layouts/check-permission-content';

import { useTelegram } from '../../../features/hooks/useTelegram';
import { useActions, useAppSelector } from '../../../features/hooks/useRedux';
import { selectOrder } from '../../../features/store/slices/order';
import { PERMISSION_CREATE_ORDER } from '../../../features/constants';
import { selectAuth } from '../../../features/store/slices/auth';

export const Header = () => {
  const { t } = useTranslation('common');

  const { onClose, tg } = useTelegram();

  const { order, isCreationProgress } = useAppSelector(selectOrder);
  const { user } = useAppSelector(selectAuth);
  const { setIsCreationProgressOrder, updateChannels } = useActions();

  const [isOpen, setIsOpen] = useState(false);

  const onSubmitToLink = () => {
    setIsOpen(false);
  };

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleClearChannels = () => {
    updateChannels([]);
  };

  return (
    <div className="h-12 w-full px-2 sticky top-0 z-50 bg-white shadow-md">
      <div className="container">
        <div className="flex justify-between items-center h-full">
          <Button onClick={toggleDrawer}>
            <MenuOutlined />
          </Button>
          <Drawer
            title={
              <div className="flex justify-between items-center gap-2">
                <p>{user ? user?.fullName : t('sidebar.not_auth')}</p>
                <LangSwitcher />
              </div>
            }
            placement="left"
            closable={true}
            onClose={toggleDrawer}
            closeIcon={<CloseCircleOutlined />}
            open={isOpen}
            key="left"
            footer={
              <div>
                {tg.initDataUnsafe.user ? (
                  <Button type="dashed" onClick={onClose}>
                    {t('sidebar.close')}
                  </Button>
                ) : (
                  <Logout />
                )}
              </div>
            }
          >
            <Menu onSubmitToLink={onSubmitToLink} />
          </Drawer>
          <CheckPermissionContent permissionId={PERMISSION_CREATE_ORDER}>
            {order.channels.length > 0 && !isCreationProgress && (
              <div className="flex items-center gap-2 ml-3">
                <span className="text-sm">
                  {t('selected')}: {order.channels.length}
                </span>
                <Button danger onClick={handleClearChannels}>
                  {t('cancel')}
                </Button>
                <Link to="/create_order" onClick={() => setIsCreationProgressOrder(true)}>
                  <Button>{t('sidebar.next')}</Button>
                </Link>
              </div>
            )}
          </CheckPermissionContent>
        </div>
      </div>
    </div>
  );
};
