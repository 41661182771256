import { AxiosResponse } from 'axios';

import { LocaleType } from './types';

import { requestToNexus } from 'api/axios';

const localeId: string = process.env.REACT_APP_LOCALE_ID || '';

export const localeService = {
  getLocale: () => {
    return requestToNexus.get<void, AxiosResponse<LocaleType>>(`/locales/${localeId}`).then((res) => res.data);
  },
};
