export const QUERY_KEY_GET_ORDER_BY_ID = 'QUERY_KEY_GET_ORDER_BY_ID';
export const QUERY_KEY_GET_ALL_CHANNELS = 'QUERY_KEY_GET_ALL_CHANNELS';
export const QUERY_KEY_GET_COMPANY_STATISTICS = 'QUERY_KEY_GET_COMPANY_STATISTICS';
export const QUERY_KEY_GET_ORDER_STATISTICS = 'QUERY_KEY_GET_ORDER_STATISTICS';
export const QUERY_KEY_GET_MARGIN = 'QUERY_KEY_GET_MARGIN';
export const QUERY_KEY_GET_TRANSACTIONS = 'QUERY_KEY_GET_TRANSACTIONS';
export const QUERY_KEY_GET_STATISTICS_STATUS = 'QUERY_KEY_GET_STATISTICS_STATUS';
export const QUERY_KEY_GET_REPORTS = 'QUERY_KEY_GET_REPORTS';
export const QUERY_KEY_GET_REPORT_BUYERS = 'QUERY_KEY_GET_REPORT_BUYERS';
export const QUERY_KEY_GET_PERSONAL_REPORT_BUYERS = 'QUERY_KEY_GET_PERSONAL_REPORT_BUYERS';
export const QUERY_KEY_CHECK_AUTH = 'QUERY_KEY_CHECK_AUTH';
export const QUERY_KEY_GET_ALL_DICTIONARY = 'QUERY_KEY_GET_ALL_DICTIONARY';
export const QUERY_KEY_GET_ONE_DICTIONARY = 'QUERY_KEY_GET_ONE_DICTIONARY';
export const QUERY_KEY_INFINITY_DICTIONARY = 'QUERY_KEY_INFINITY_DICTIONARY';
export const QUERY_KEY_GET_ALL_CLIENTS = 'QUERY_KEY_GET_ALL_CLIENTS';
export const QUERY_KEY_GET_COMPANY_BY_ID = 'QUERY_KEY_GET_COMPANY_BY_ID';
export const QUERY_KEY_GET_PRICES = 'QUERY_KEY_GET_PRICES';
export const QUERY_KEY_GET_CATEGORIES = 'QUERY_KEY_GET_CATEGORIES';
export const QUERY_KEY_GET_USERS = 'QUERY_KEY_GET_USERS';
export const QUERY_KEY_GET_USER_BY_ID = 'QUERY_KEY_GET_USER_BY_ID';
export const QUERY_KEY_GET_CHANNELS_STATISTICS = 'QUERY_KEY_GET_CHANNELS_STATISTICS';
export const QUERY_KEY_GET_LAST_UPDATE_CHANNELS_STATISTICS = 'QUERY_KEY_GET_LAST_UPDATE_CHANNELS_STATISTICS';
export const QUERY_KEY_GET_PERMISSIONS = 'QUERY_KEY_GET_PERMISSIONS';
export const QUERY_KEY_GET_ROLES = 'QUERY_KEY_GET_ROLES';
export const QUERY_KEY_GET_ROLE_BY_ID = 'QUERY_KEY_GET_ROLE_BY_ID';
export const QUERY_KEY_GET_LOCALE = 'QUERY_KEY_GET_LOCALE';
export const QUERY_KEY_GET_TIME_OF_PLACEMENT = 'QUERY_KEY_GET_TIME_OF_PLACEMENT';
