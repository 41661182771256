import { createContext } from 'react';

import { LocaleType } from 'api/locale/types';

export type LocaleContextType = {
  locale: LocaleType | null;
  isPending: boolean;
  getStatusRemoveBanners: () => boolean;
};

export const LocaleContext = createContext<LocaleContextType>({
  getStatusRemoveBanners: () => false,
  isPending: false,
  locale: null,
});
