import { useQuery } from '@tanstack/react-query';

import { localeService } from '../service';

import { QUERY_KEY_GET_LOCALE } from 'api/keys';

export const useGetLocale = () => {
  return useQuery({
    queryKey: [QUERY_KEY_GET_LOCALE],
    queryFn: () => localeService.getLocale(),
    refetchInterval: 2000,
  });
};
